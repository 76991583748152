.container {
  position: absolute;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.image{
  width: 700px;
  margin: -50px auto;
 
  
}

