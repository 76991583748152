/* ProjectCard.css */

.project-description-card {
  display: flex;
  height: 100%;
  background-color: #002345;
  border: 2px solid #e0e0e081;
  backdrop-filter: blur(40px);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.7);
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  /* Hover effect */
  &:hover {
    box-shadow: 0px 0px 15px 10px #67e8f9; /* rgba(0, 157, 255, 0.7); */
    background-color: #010116; /* #002345; */
    transform: scale(1.02);
  }
}

.center-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.project-image {
  margin: auto;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  overflow: hidden;
  height: 100%;
  background-color: blue;
  display: flex;
}

.project-image img {
  flex: 1;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  transition: transform 0.4s ease-in-out;
}
.project-image img:hover {
  transform: scale(1.2);
}

.project-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.project-details {
  padding: 20px;
}

.project-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
}

.project-description {
  max-width: 90ch;
  font-size: 16px;
  color: #636f7a;
}

.tech-used {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
}

.project-links {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.project-links > a > img {
  width: 2rem;

  /* Hover effect */
  &:hover {
    filter: brightness(0) saturate(100%) invert(56%) sepia(49%) saturate(6950%)
      hue-rotate(180deg) brightness(102%) contrast(109%);
  }
}

@media screen and (max-width: 700px) {
  .project-description-card {
    display: flex;
    flex-direction: column;
  }
  .project-image {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .project-image img {
    object-fit: cover;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
