/* MainProject.css */

.main-project {
    width: 80%;
    height: 22rem;
    margin: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }



  @media screen and (max-width: 700px) {
    .main-project {
      height: auto;
    }
  }
  